import React, { useState, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPrint,
  faTimes,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Url } from "../../Component/function/FunctionApi";
import { getCookie } from "../../Pages/Login/Login";
import { fetchDataWithRetries } from "../../Component/function/FunctionApi";
import "./AddInvoice.css";
import Swal from "sweetalert2";

const getToken = () => {
  const userCookie = getCookie("user");
  return userCookie ? JSON.parse(userCookie).token : null;
};

function InvoiceForm({ userData }) {
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [status, setStatus] = useState("جديد");
  const [customer, setCustomer] = useState("");
  const [governorates, setGovernorates] = useState([]);
  const [selectedGovernorate, setSelectedGovernorate] = useState("");
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [page, setPage] = useState("");
  const [shipping, setShipping] = useState(0);
  const [isUrgent, setIsUrgent] = useState(false);
  const [notReplied, setNotReplied] = useState(false);
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [total, setTotal] = useState(0);
  const [numbers, setNumbers] = useState([]);
  const [isPhoneFound, setIsPhoneFound] = useState(false);
  const [totalWithShipping, setTotalWithShipping] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newItem, setNewItem] = useState({
    id: "",
    quantity: 0,
    price: 0,
    discount: 0,
  });

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    setPhone(input);
    // Check if the entered phone is in the array
    setIsPhoneFound(numbers.includes(input));
  };
  const [selectedItem, setSelectedItem] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchGovernorates = async () => {
      try {
        console.log("numberssssssssssssssssssssss");
        const numberResponse = await axios.get(
          `${Url}Invoice/GetPhoneNumbers`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        setNumbers(numberResponse.data.data);
        const response = await axios.get(`${Url}Governorate/get-governorates`);
        setGovernorates(response.data.data);
      } catch (error) {
        console.error("Error fetching governorates:", error);
      }
    };
    fetchGovernorates();
  }, [getToken()]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${Url}Category/get-categories`);
        setCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const calculatedTotal = items.reduce(
      (acc, item) => acc + (item.price * item.quantity - item.discount),
      0
    );
    setTotal(calculatedTotal);
  }, [items]);

  useEffect(() => {
    setTotalWithShipping(total + parseFloat(shipping));
  }, [total, shipping]);

  const handleAddItemClick = () => {
    setNewItem({ id: "", quantity: 0, price: 0, discount: 0 });
    setIsModalOpen(true);
  };

  const handleRowClick = (index) => {
    const item = items[index];
    setSelectedItem(item);
    setIsModalOpen(true);
    setNewItem(item);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    setNewItem({ id: "", quantity: 0, price: 0, discount: 0 });
  };

  const handleSaveItem = () => {
    if (selectedItem) {
      setItems((prevItems) =>
        prevItems.map((item) => (item.id === selectedItem.id ? newItem : item))
      );
    } else {
      setItems((prevItems) => [...prevItems, newItem]);
    }
    handleCloseModal();
  };

  const handleDeleteItem = () => {
    setItems(items.filter((item) => item.id !== selectedItem.id));
    handleCloseModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadProgress(0);
    setMessage("");

    const invoiceData = {
      invoiceDate: new Date(date).toISOString(),
      status,
      customer,
      governorateId: parseInt(selectedGovernorate),
      note,
      phone,
      address,
      page,
      shipping: parseFloat(shipping),
      isUrgent,
      notReplied,
      categoryIds: items.map((item) => ({
        id: item.id,
        quantity: item.quantity,
        price: parseFloat(item.price),
        discount: parseFloat(item.discount),
      })),
    };

    try {
      const response = await axios.post(
        `${Url}Invoice/create-invoice`,
        invoiceData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );

      if (response.data.message) {
        setMessage("تم إضافة الفاتورة بنجاح");
        setTimeout(
          () => {
            window.location.reload();
          },
          [],
          500
        );
      } else {
        setMessage("لم تتم إضافة الفاتورة.");
      }
    } catch (error) {
      Swal.fire("Error", error.response.data.message, "error");
    } finally {
      setUploadProgress(0);
    }
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(
      (category) => category.id === parseInt(e.target.value)
    );
    if (selectedCategory) {
      setNewItem({
        ...newItem,
        id: selectedCategory.id,
      });
    }
  };

  const handleDiscountChange = (e) => {
    const discountValue = parseFloat(e.target.value) || 0;
    setNewItem({ ...newItem, discount: discountValue });
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");

    const itemsRows = items
      .map(
        (item, index) => `
        <tr key=${index}>
      
            <td>${item.address || address}</td>
        
            <td>${customer}</td>
      
            <td>${phone}</td>
            <td>${item.total || total}</td>
            <td>${note}</td>
        </tr>
    `
      )
      .join("");

    printWindow.document.write(`
        <html>
            <head>
                <title>فواتير</title>
                <style>
                    /* Base style for body */
                    body {
                        font-family: 'Amiri', sans-serif;
                        direction: rtl;
                        text-align: right;
                        margin: 0;
                        padding: 0;
                    }
                    
                    /* Container with margin */
                    .container {
                        margin: 1rem;
                    }
                    
                    /* Table styling */
                    table {
                        width: 100%; 
                        overflow-x: auto;
                        margin-bottom: 1.25rem; /* 20px */
                        font-size: 0.875rem; /* 14px */
                    }
                    
                    /* Table header and cells styling */
                    th, td {
                        border: 1px solid #ddd; /* Lighter border color */
                        padding: 0.2rem; /* 10px */
                        text-align: right;
                    }
                    
                    /* Table header background and font weight */
                    th {
                        background-color: #f9fafb; /* Light gray for better contrast */
                        font-weight: 600;
                    }
                    
                    /* Styling for image container */
                    .file-list img {
                        width: 250px;
                        margin: 0.5rem;
                        border-radius: 0.375rem; /* Rounded corners */
                    }
                    
                    /* PDF link styling */
                    .file-item {
                        margin: 0.2rem;
                        text-decoration: none;
                        color: #3182ce; /* Tailwind blue-500 */
                    }
                    
                    .file-item:hover {
                        text-decoration: underline;
                    }
                </style>
            </head>
            <body>
                <div class="container">
                    <h2>الفواتير - ${date}</h2>
                    <p><strong>الحالة:</strong> ${status}</p>
                    <p><strong>العميل:</strong> ${customer}</p>
                    <p><strong>الهاتف:</strong> ${phone}</p>
                    <p><strong>العنوان:</strong> ${address}</p>
                    <p><strong>ملاحظات:</strong> ${note}</p>
                    <table>
                        <thead>
                            <tr>
                            
                                <th>العنوان</th>
                          
                                <th>العميل</th>
                      
                                <th>رقم الهاتف</th>
                                <th>الاجمالي</th>
                                <th>ملاحظات</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${itemsRows}
                        </tbody>
                    </table>
                    <p><strong>الشحن:</strong> ${shipping}</p>
                    <p><strong>الإجمالي مع الشحن:</strong> ${totalWithShipping}</p>
                </div>
            </body>
        </html>
    `);

    printWindow.document.close();
  };

  return (
    <div className="invoice-page" dir="rtl">
      <div className="invoice-header">
        <h2>فاتوره جديده</h2>
        <div className="action-buttons">
          <button
            className={`action-button px-4 py-2 rounded text-white font-medium ${
              isPhoneFound
                ? "bg-slate-200 opacity-20 cursor-disable"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
            onClick={handleSubmit}
            disabled={isPhoneFound}
          >
            <FontAwesomeIcon icon={faSave} /> حفظ
          </button>

          <button className="action-button" onClick={handlePrint}>
            <FontAwesomeIcon icon={faPrint} /> طباعه
          </button>
        </div>
      </div>

      <form className="invoice-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label>التاريخ</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>الحالة</label>
            <input
              type="text"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              placeholder="الحالة"
            />
          </div>
          <div className="form-group">
            <label>العميل</label>
            <input
              type="text"
              value={customer}
              onChange={(e) => setCustomer(e.target.value)}
              placeholder="العميل"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>المحافظة</label>
            <select
              value={selectedGovernorate}
              onChange={(e) => setSelectedGovernorate(e.target.value)}
            >
              <option value="">اختر المحافظة</option>
              {governorates.map((gov) => (
                <option key={gov.id} value={gov.id}>
                  {gov.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>الملاحظة</label>
            <input
              type="text"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="الملاحظة"
            />
          </div>
          <div className="form-group">
            <label>التليفون</label>
            <input
              type="text"
              value={phone}
              onChange={handlePhoneChange}
              placeholder="التليفون"
              className="form-control"
            />
            {isPhoneFound && (
              <p style={{ color: "green", marginTop: "8px" }}>
                يوجد فاتوره بنفس الرقم
              </p>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>العنوان</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="العنوان"
            />
          </div>
          <div className="form-group">
            <label>الصفحة</label>
            <input
              type="text"
              value={page}
              onChange={(e) => setPage(e.target.value)}
              placeholder="الصفحة"
            />
          </div>
          <div className="form-group">
            <label>الشحن</label>
            <input
              type="number"
              value={shipping}
              onChange={(e) => setShipping(e.target.value)}
              placeholder="الشحن"
            />
          </div>
        </div>

        <div className="form-row checkboxes">
          <div className="checkbox-group single">
            <input
              type="checkbox"
              checked={isUrgent}
              onChange={(e) => setIsUrgent(e.target.checked)}
              id="urgent"
            />
            <label htmlFor="urgent">فاتوره مستعجله</label>
          </div>
          <div className="checkbox-group double">
            <div>
              <input
                type="checkbox"
                checked={notReplied}
                onChange={(e) => setNotReplied(e.target.checked)}
                id="no-response"
              />
              <label htmlFor="no-response">لم يتم الرد</label>
            </div>
            <div className="placeholder"></div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>الإجمالي</label>
            <input type="text" value={total.toFixed(2)} readOnly />
          </div>
          <div className="form-group">
            <label>الإجمالي بالشحن</label>
            <input type="text" value={totalWithShipping.toFixed(2)} readOnly />
          </div>
        </div>

        <button
          type="button"
          className="add-item-button"
          onClick={handleAddItemClick}
        >
          إضافة صنف +
        </button>

        <div className="invoice-table-container">
          <table className="invoice-table">
            <thead>
              <tr>
                <th>الصنف</th>
                <th>الكمية</th>
                <th>السعر</th>
                <th>الخصم</th>
                <th>الإجمالي</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index} onClick={() => handleRowClick(index)}>
                  <td>
                    {categories.find((cat) => cat.id === item.id)?.name ||
                      "Unknown"}
                  </td>
                  <td>{item.quantity}</td>
                  <td>{item.price.toFixed(2)}</td>
                  <td>{item.discount.toFixed(2)}</td>
                  <td>
                    {(item.price * item.quantity - item.discount).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </form>

      {isModalOpen && (
        <div className="modal-overlay !z-[100000000000000000]">
          <div className="modal-content">
            <div className="modal-header">
              <h3>{selectedItem ? "تعديل الصنف" : "إضافة صنف جديد"}</h3>
              <FontAwesomeIcon
                icon={faTimes}
                className="modal-close"
                onClick={handleCloseModal}
              />
            </div>
            <hr />
            <div className="modal-body">
              <div className="form-row">
                <div className="form-group">
                  <label>الصنف</label>
                  <select value={newItem.id} onChange={handleCategoryChange}>
                    <option value="">اختر الصنف</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>الكمية</label>
                  <input
                    type="number"
                    value={newItem.quantity}
                    onChange={(e) =>
                      setNewItem({
                        ...newItem,
                        quantity: parseInt(e.target.value),
                      })
                    }
                    placeholder="الكمية"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>السعر</label>
                  <input
                    type="number"
                    value={newItem.price}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (value > 0 || e.target.value === "") {
                        setNewItem({
                          ...newItem,
                          price: value,
                        });
                      }
                    }}
                    placeholder="السعر"
                  />
                </div>
                <div className="form-group">
                  <label>الخصم</label>
                  <input
                    type="number"
                    value={newItem.discount}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (value > 0 || e.target.value === "") {
                        setNewItem({
                          ...newItem,
                          discount: value,
                        });
                      }
                    }}
                    placeholder="الخصم"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={handleSaveItem}>
                <FontAwesomeIcon icon={faSave} />{" "}
                {selectedItem ? "حفظ التعديلات" : "إضافة الصنف"}
              </button>
              {selectedItem && (
                <button
                  className="action-button delete-button"
                  onClick={handleDeleteItem}
                >
                  <FontAwesomeIcon icon={faTrash} /> حذف
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {uploadProgress > 0 && (
        <div className="modal-overlay">
          <div className="modal-content">
            <CircularProgressbar
              value={uploadProgress}
              text={`${uploadProgress}%`}
            />
            <button
              className="icondeleteModal"
              onClick={() => setUploadProgress(0)}
            >
              &times;
            </button>
          </div>
        </div>
      )}

      {message && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div
              className={`message ${
                message.includes("خطأ") ? "error" : "success"
              }`}
            >
              {message}
              <button
                className="icondeleteModal"
                onClick={() => setMessage("")}
              >
                &times;
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InvoiceForm;
