import React, { createContext, useContext, useEffect, useState } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import axios from "axios";
import { Url } from "../Component/function/FunctionApi";

const SignalRContext = createContext();

export const useSignalR = () => {
  return useContext(SignalRContext);
};

export const SignalRProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Starting SignalR connection...");

    const newConnection = new HubConnectionBuilder()
      .withUrl(`https://wafarnalk.runasp.net/chatHub`)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(newConnection);

    newConnection
      .start()
      .then(() => {
        newConnection.on("ReceiveMessages", (data) => {
          console.log(
            "#####################################################################"
          );
          console.log("Received data message:", data);

          console.log(data);
          console.log("Received data message:", data);
          const n = [...data, ...messages];
          setMessages((prev) => n);
        });
      })
      .catch((error) => console.error("SignalR connection failed:", error));

    return () => {
      newConnection
        .stop()
        .then(() => console.log("Disconnected from SignalR server"))
        .catch((error) => console.error("Error disconnecting:", error));
    };
  }, []);

  const fetchMessages = async (conversationId) => {
    console.log("Fetching messages for conversation:", conversationId);
    if (!conversationId) return;

    try {
      await connection.invoke("GetMessages", conversationId);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    }
  };

  const sendMessage = async (data) => {
    console.log("Sending message:", data);
    try {
      await connection.invoke(
        "SendMessage",
        data.conversationId,
        data.senderId,
        data.receiverId,
        data.content
      );
      console.log("Message sent:", data);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <SignalRContext.Provider
      value={{
        connection,
        messages,
        sendMessage,
        fetchMessages,
        loading,
        setMessages,
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};
