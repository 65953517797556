import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa"; // Icons for toggle
import "./ChatApp.css";
import avatar from "../../Component/img/circle.png";
import { Domin } from "../../Component/function/FunctionApi";

const SidebarChat = ({ data, onFriendSelect, selectedFriendId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const filtered = data.filter((friend) =>
      friend.displayName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchQuery, data]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div>
      <button
        className="lg:hidden p-2 absolute top-5   left-52 text-gray-500 rounded-md hover:bg-gray-200 transition duration-200"
        onClick={toggleSidebar}
      >
        {isOpen ? <FaTimes size={20} /> : <FaBars size={20} />}
      </button>

      {/* Sidebar */}
      <div
        className={`transition-all duration-300 transform bg-white  w-64 ${
          isOpen ? "fixed left-0 top-0 h-screen z-10" : "lg:block hidden"
        }`}
      >
        <div className="search p-2! lg:p-4!">
          <input
            type="text"
            placeholder="ابحث عن شخص"
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        {/* List of friends */}
        <div className="friend-list bg-white overflow-auto h-[80vh]">
          {filteredData.map((friend) => (
            <div
              key={friend.id}
              className={`friend p-2  lg:p-4 flex items-center justify-between cursor-pointer rounded-lg ${
                selectedFriendId === friend.id
                  ? "bg-blue-100"
                  : "hover:bg-gray-100"
              }`}
              onClick={() => {
                onFriendSelect(friend.id);
                setIsOpen(false);
                // if (window.innerWidth < 640) setIsOpen(false);
              }}
            >
              <div className="friend-info flex flex-col">
                <h4 className="lg:text-lg text-sm font-semibold text-gray-800">
                  {friend.displayName}
                </h4>
              </div>
              <img
                src={friend.userImage ? `${Domin}/${friend.userImage}` : avatar}
                alt={`${friend.displayName}'s avatar`}
                className="friend-avatar w-6 h-6  lg:w-10 lg:h-10 rounded-full"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SidebarChat;
