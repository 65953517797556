import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faTrash,
  faTimes,
  faEdit,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { fetchDataWithRetries } from "../../Component/function/FunctionApi";
import { Url } from "../../Component/function/FunctionApi";
import "./Roles.css";
import Swal from "sweetalert2";

function ComponentGover() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteSuccessModalOpen, setIsDeleteSuccessModalOpen] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [newUser, setNewUser] = useState({
    id: "",
    userName: "",
    displayName: "",
    password: "",
    userType: "",
    userImage: null,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [apiStatus, setApiStatus] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      await fetchDataWithRetries(
        "Account/accounts",
        setUsersData,
        setApiStatus
      );
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchPermissions = async () => {
      await fetchDataWithRetries(
        "Account/permissions",
        setPermissionsData,
        setApiStatus
      );
    };
    fetchPermissions();
  }, []);

  const handleOpenModalForAdd = () => {
    setIsModalOpen(true);
    setIsEditMode(false);
    setNewUser({
      id: "",
      userName: "",
      displayName: "",
      password: "",
      userType: "",
      userImage: null,
    });
  };
  const handleOpenModalForEdit = (user) => {
    setIsModalOpen(true);
    setIsEditMode(true);
    setNewUser(user);
  };

  const handleOpenPermissionModal = (user) => {
    console.log("from handle permission ");
    console.log(user);
    setNewUser(user);
    setSelectedPermissions(user.permissions || []);
    setIsPermissionModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsPermissionModalOpen(false);
    setIsSuccessModalOpen(false);
    setIsErrorModalOpen(false);
    setIsDeleteSuccessModalOpen(false);
    setSelectedPermissions([]);
    setNewUser({
      id: "",
      userName: "",
      displayName: "",
      password: "",
      userType: "",
      userImage: null,
    });
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setNewUser((prevData) => ({ ...prevData, userImage: e.target.files[0] }));
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append("userName", newUser.userName);
      formData.append("displayName", newUser.displayName);
      formData.append("password", newUser.password);
      formData.append("userType", newUser.userType);
      formData.append("userImage", newUser.userImage);

      if (isEditMode) {
        await axios.put(`${Url}Account/update-account`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        await axios.post(`${Url}Account/register`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      await fetchDataWithRetries(
        "Account/accounts",
        setUsersData,
        setApiStatus
      );
      handleCloseModal();
      setSuccessMessage("تم حفظ المستخدم بنجاح!");
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error("Error saving user:", error);
      setErrorMessage(
        error.response?.data?.message || "حدث خطأ غير متوقع. حاول مرة أخرى."
      );
      setIsErrorModalOpen(true);
    }
  };
  const getData = async () => {
    await fetchDataWithRetries("Account/accounts", setUsersData, setApiStatus);
  };

  const confirmDeleteUser = async () => {
    if (!userToDelete) return;
    try {
      await axios.delete(`${Url}Account/remove-account`, {
        params: { userName: userToDelete.userName },
      });
      setIsDeleteSuccessModalOpen(true);
      await fetchDataWithRetries(
        "Account/accounts",
        setUsersData,
        setApiStatus
      );
    } catch (error) {
      console.error("Error deleting user:", error);
      setErrorMessage("فشل في حذف المستخدم. حاول مرة أخرى.");
      setIsErrorModalOpen(true);
    } finally {
      setIsConfirmDeleteOpen(false);
    }
  };

  const handleOpenConfirmDelete = (user) => {
    setUserToDelete(user);
    setIsConfirmDeleteOpen(true);
  };

  const handlePermissionChange = (permissionName) => {
    setSelectedPermissions((prev) =>
      prev.includes(permissionName)
        ? prev.filter((p) => p !== permissionName)
        : [...prev, permissionName]
    );
  };

  const handleSavePermissions = async () => {
    try {
      const userName = newUser.userName;

      if (!userName) {
        throw new Error("اسم المستخدم غير موجود");
      }
      const formData = new FormData();
      formData.append("userName", userName);
      selectedPermissions.forEach((permission) => {
        formData.append("permissionNames", permission);
      });
      console.log(formData);
      await axios.post(`${Url}Account/assign-permissions`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      await fetchDataWithRetries(
        "Account/accounts",
        setUsersData,
        setApiStatus
      );
      setIsPermissionModalOpen(false);
      setSuccessMessage("تم حفظ الأذونات بنجاح!");
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error("Error assigning permissions:", error);
      setErrorMessage("هذه الاوزونات موجوده بالفعل  حاول");
      setIsErrorModalOpen(true);
    }
  };

  const toggleUserActivation = async (user) => {
    console.log(user);
    const newStatus = user.isActive === true ? false : true;
    console.log(newStatus);
    const action = newStatus ? "تنشيط" : "إلغاء التنشيط";

    const result = await Swal.fire({
      title: `هل أنت متأكد من ${action} المستخدم؟`,
      text: `سيتم ${action} المستخدم ${user.displayName}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "نعم، استمر",
      cancelButtonText: "إلغاء",
    });

    if (result.isConfirmed) {
      try {
        await axios.put(
          `${Url}Account/IsActiveOrNoActive?userid=${user.id}&value=${newStatus}`
        );

        // Success message
        Swal.fire({
          title: "تم التحديث بنجاح",
          text: `تم ${action} المستخدم ${user.displayName}.`,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        getData();
        // Update user status in the UI
        user.isActive = newStatus;
      } catch (error) {
        // Error handling
        Swal.fire({
          title: "خطأ",
          text: "حدث خطأ أثناء تحديث حالة المستخدم. يرجى المحاولة مرة أخرى.",
          icon: "error",
        });
      }
    }
  };
  return (
    <div className="provinces-container">
      <div className="header-section">
        <h2>المستخدمون</h2>
        <button className="add-button" onClick={handleOpenModalForAdd}>
          اضافة مستخدم +
        </button>
      </div>

      <table className="provinces-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>اسم المستخدم</th>
            <th>اسم العرض</th>
            <th>نوع المستخدم</th>
            <th>الإجراءات</th>
          </tr>
        </thead>
        <tbody>
          {usersData.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.userName}</td>
              <td>{user.displayName}</td>
              <td>{user.userType || "لا يوجد"}</td>
              <td className="p-4 border-t text-sm text-gray-700">
                <div className="flex items-center gap-2 justify-center">
                  <button
                    className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-300 focus:outline-none transition"
                    onClick={() => handleOpenModalForEdit(user)}
                  >
                    <FontAwesomeIcon icon={faEdit} /> تعديل
                  </button>

                  <button
                    className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:ring-2 focus:ring-red-300 focus:outline-none transition"
                    onClick={() => handleOpenConfirmDelete(user)}
                  >
                    <FontAwesomeIcon icon={faTrash} /> حذف
                  </button>

                  {user.userType === "Secretary" && (
                    <button
                      className="flex items-center gap-2 px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 focus:ring-2 focus:ring-purple-300 focus:outline-none transition"
                      onClick={() => handleOpenPermissionModal(user)}
                    >
                      <FontAwesomeIcon icon={faKey} /> تعديل الأذونات
                    </button>
                  )}

                  {user.userType === "Secretary" && (
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        id={`toggle-${user.id}`}
                        className="w-5 h-5 accent-green-500 rounded focus:ring-2 focus:ring-green-300 transition"
                        checked={user.isActive}
                        onChange={() => toggleUserActivation(user)}
                      />
                      <label
                        htmlFor={`toggle-${user.id}`}
                        className="text-gray-800 text-sm font-medium cursor-pointer"
                      >
                        {user.isActive ? "نشط" : "غير نشط"}
                      </label>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* User Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3>{isEditMode ? "تعديل مستخدم" : "اضافة مستخدم"}</h3>
              <FontAwesomeIcon
                icon={faTimes}
                className="modal-close"
                onClick={handleCloseModal}
              />
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>اسم المستخدم:</label>
                <input
                  type="text"
                  name="userName"
                  value={newUser.userName}
                  onChange={handleInputChange}
                  readOnly={isEditMode}
                  required
                />
              </div>
              <div className="form-group">
                <label>اسم العرض:</label>
                <input
                  type="text"
                  name="displayName"
                  value={newUser.displayName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>كلمة المرور:</label>
                <input
                  type="text"
                  name="password"
                  value={newUser.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>نوع المستخدم:</label>
                <select
                  name="userType"
                  value={newUser.userType}
                  onChange={handleInputChange}
                  required
                  style={{ width: "95%" }}
                >
                  <option value="">اختر نوع المستخدم</option>
                  <option value="Admin">Admin</option>
                  <option value="Secretary">Secretary</option>
                </select>
              </div>
              <div>
                <div>
                  <div className="mb-4">
                    {newUser.userImage ? (
                      <img
                        src={
                          typeof newUser.userImage === "string"
                            ? `https://sales-system.tryasp.net${newUser.userImage}`
                            : URL.createObjectURL(newUser.userImage)
                        }
                        alt="User Preview"
                        className="w-32 h-32 object-cover rounded"
                      />
                    ) : (
                      <div className="text-gray-500">No image available</div>
                    )}
                  </div>

                  <div className="form-group">
                    <label>صورة المستخدم:</label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                {/* <div className="form-group">
                  <label>صورة المستخدم:</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div> */}
              </div>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={handleSave}>
                <FontAwesomeIcon icon={faSave} /> حفظ
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Permission Modal */}
      {isPermissionModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3>تعديل الأذونات لـ {newUser.userName}</h3>
              <FontAwesomeIcon
                icon={faTimes}
                className="modal-close"
                onClick={handleCloseModal}
              />
            </div>
            <div className="modal-body">
              {permissionsData.data.map((permission) => (
                <div key={permission.id} className="permission-item">
                  <input
                    type="checkbox"
                    checked={selectedPermissions.includes(permission.name)}
                    onChange={() => handlePermissionChange(permission.name)}
                  />
                  <label>{permission.name}</label>
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={handleSavePermissions}>
                <FontAwesomeIcon icon={faSave} /> حفظ الأذونات
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {isSuccessModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3>نجاح!</h3>
              <FontAwesomeIcon
                icon={faTimes}
                className="modal-close"
                onClick={handleCloseModal}
              />
            </div>
            <div className="modal-body">
              <p>{successMessage}</p>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={handleCloseModal}>
                أغلق
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {isErrorModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3>خطأ!</h3>
              <FontAwesomeIcon
                icon={faTimes}
                className="modal-close"
                onClick={handleCloseModal}
              />
            </div>
            <div className="modal-body">
              <p>{errorMessage}</p>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={handleCloseModal}>
                أغلق
              </button>
            </div>
          </div>
        </div>
      )}

      {isConfirmDeleteOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3>تأكيد الحذف</h3>
              <FontAwesomeIcon
                icon={faTimes}
                className="modal-close"
                onClick={handleCloseModal}
              />
            </div>
            <div className="modal-body">
              <p>
                هل أنت متأكد أنك تريد حذف المستخدم {userToDelete?.userName}؟
              </p>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={confirmDeleteUser}>
                نعم
              </button>
              <button
                className="action-button"
                onClick={() => setIsConfirmDeleteOpen(false)}
              >
                لا
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Success Modal */}
      {isDeleteSuccessModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3>تم الحذف!</h3>
              <FontAwesomeIcon
                icon={faTimes}
                className="modal-close"
                onClick={handleCloseModal}
              />
            </div>
            <div className="modal-body">
              <p>تم حذف المستخدم بنجاح!</p>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={handleCloseModal}>
                أغلق
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ComponentGover;
