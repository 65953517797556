import React, { useState, useEffect } from "react";
import { postData, putData, Url } from "../../Component/function/FunctionApi";
import logo from "../../assets/images/weviira.png";
import Swal from "sweetalert2";

import {
  fetchDataWithRetries,
  deleteFunction,
} from "../../Component/function/FunctionApi";
import "./Invoices.css";
import { BsPencilSquare } from "react-icons/bs";
import { FaEye, FaPlus, FaPrint } from "react-icons/fa";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FaTrash } from "react-icons/fa";
import { getCookie } from "../Login/Login";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import usePrintInvoice from "../../hooks/invoice/printInvoice";
import useShowInvoice from "../../hooks/invoice/ShowInvoice";
const getToken = () => {
  const userCookie = getCookie("user");
  return userCookie ? JSON.parse(userCookie).token : null;
};
const Invoices = () => {
  const { handlePrintInvoice } = usePrintInvoice(logo);
  const { showInvoice } = useShowInvoice();
  const [representatives, setRepresentatives] = useState([]);
  const [selectedRepresentative, setSelectedRepresentative] = useState("");
  const [categories, setCategories] = useState([]);
  const [invoicesData, setInvoicesData] = useState({ data: [] });
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [selectedGovernorate, setSelectedGovernorate] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogType, setDialogType] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedInvoiceItems, setSelectedInvoiceItems] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [newItem, setNewItem] = useState({
    categoryId: "",
    quantity: 0,
    price: 0,
    discount: 0,
  });

  const handleDeleteItem = async (item) => {
    // Show SweetAlert2 confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      await deleteFunction(
        `Invoice/delete-invoice-categoryByid?categoryid=${item.id}`
      );

      setSelectedInvoiceItems((prev) => prev.filter((i) => i.id !== item.id));
      window.location.reload();
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  };
  const printInvoice = (invoice) => {
    handlePrintInvoice(invoice);
  };
  const handleSaveItem = async (e) => {
    e.preventDefault();
    try {
      // Prepare form data

      const arr = [
        {
          id: newItem.categoryId,
          quantity: newItem.quantity,
          price: newItem.price,
          discount: newItem.discount,
        },
      ];
      await axios.post(
        `${Url}Invoice/AddInvoiceCategory?invoiceId=${selectedInvoice.id}`,
        arr,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      // Close the modal and notify the user
      handleCloseModal();
      Swal.fire({
        title: "Success",
        text: "Item added successfully!",
        icon: "success",
        timer: 3000,
        timerProgressBar: true,
      }).then(() => {
        window.location.reload();
      });
    } catch (e) {
      // Log and display error message
      console.error("Error saving item:", e);
      Swal.fire("Error", "Failed to save the item. Please try again.", "error");
    }
  };

  const handleEditItemRequest = async (e) => {
    e.preventDefault();
    try {
      // Prepare form data

      const arr = [
        {
          invoiceCategoryId: newItem.categoryId,
          quantity: newItem.quantity,
          price: newItem.price,
          discount: newItem.discount,
        },
      ];
      await axios.put(
        `${Url}Invoice/invoice-categories?invoiceId=${selectedInvoice.id}`,
        arr,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      // Close the modal and notify the user
      handleCloseModal();
      Swal.fire({
        title: "Success",
        text: "Item added successfully!",
        icon: "success",
        timer: 3000,
        timerProgressBar: true,
      }).then(() => {
        window.location.reload();
      });
    } catch (e) {
      // Log and display error message
      console.error("Error saving item:", e);
      Swal.fire("Error", "Failed to save the item. Please try again.", "error");
    }
  };

  const handleEditItem = (item) => {
    console.log(item);
    setNewItem({
      categoryId: item.id || "",
      quantity: item.quantity || 0,
      price: item.price || 0,
      discount: item.discount || 0,
    });
    setIsModalOpen(true);
    setEditMode(true);
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(
      (category) => category.id === parseInt(e.target.value)
    );

    setNewItem({
      ...newItem,
      categoryId: selectedCategory.id,
    });
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);

    setNewItem({ id: "", quantity: 0, price: 0, discount: 0 });
  };
  const getToken = () => {
    const userCookie = getCookie("user");
    return userCookie ? JSON.parse(userCookie).token : null;
  };

  console.log(selectedInvoiceItems);
  useEffect(() => {
    const fetchGovernorates = async () => {
      await fetchDataWithRetries(
        "Represntative/get-Represntatives",
        setRepresentatives
      );

      const response = await axios.get(`${Url}Category/get-categories`);
      setCategories(response.data.data);
      fetchDataWithRetries();
      try {
        console.log("numberssssssssssssssssssssss");
        const numberResponse = await axios.get(
          `${Url}Invoice/GetPhoneNumbers`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        setNumbers(numberResponse.data.data);
        const response = await axios.get(`${Url}Governorate/get-governorates`);
        setGovernorates(response.data.data);
      } catch (error) {
        console.error("Error fetching governorates:", error);
      }
    };
    fetchGovernorates();
  }, [getToken()]);

  const fetchInvoices = async () => {
    const result = await fetchDataWithRetries(
      "Invoice/GetAllInvoices",
      setInvoicesData
    );
    if (result && result.data) {
      setInvoicesData(result);
      setFilteredInvoices(result.data);
    }
  };
  const handleOpenDialog = (invoice) => {
    setSelectedInvoice(invoice);
    console.log(invoice);
    setSelectedInvoiceItems(invoice.invoiceCategories);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveChanges = async () => {
    console.log(selectedInvoice);

    console.log(selectedInvoice);
    try {
      console.log("Updated Invoice:", selectedInvoice);
      const response = await putData(
        `Invoice/update/${selectedInvoice.id}`,
        selectedInvoice
      );

      if (response.status === 200) {
        setDialogMessage("تم تحديث الفاتورة بنجاح!");
        setDialogType("success");
      } else {
        setDialogMessage("فشل في تحديث الفاتورة. حاول مرة أخرى.");
        setDialogType("error");
      }
      Swal.fire({
        title: "Success!",
        text: "Item was added successfully to the invoice.",
        icon: "success",
        confirmButtonText: "OK",
        timer: 3000,

        customClass: {
          confirmButton: "swal-confirm-btn",
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 200);
      setOpenDialog(false);
    } catch (error) {
      setOpenDialog(false);
      console.error("Error updating invoice:", error);
      setDialogMessage("حدث خطأ. حاول مرة أخرى.");
      setDialogType("error");
      setDialogOpen(true); // Open dialog
    }
  };
  console.log(invoicesData);
  const handleClose = () => {
    fetchInvoices();
    setDialogOpen(false);
    window.location.reload();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedInvoice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDelete = async (invoice) => {
    console.log(invoice);
    try {
      // Show confirmation dialog
      const result = await Swal.fire({
        title: "هل أنت متأكد؟",
        text: "لا يمكنك التراجع عن هذا الإجراء بعد الحذف!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e63946",
        cancelButtonColor: "#457b9d",
        confirmButtonText: "نعم، احذفها!",
        cancelButtonText: "إلغاء",
      });

      if (result.isConfirmed) {
        await deleteFunction(`Invoice/invoice?id=${invoice.id}`);
        fetchInvoices(); 

      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
      Swal.fire(
        "Error!",
        "An unexpected error occurred.",
        error.response.data.message
      );
    }
  };

  const [filters, setFilters] = useState({
    code: "",
    date: "",
    client: "",
    phone: "",
    address: "",
    user: "",
    representative: "",
    status: "",
    quantity: "",
    governorate: "",
  });

  // Fetch invoices data
  useEffect(() => {
    fetchInvoices();
  }, []);

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "code") {
      setFilters({ ...filters, [name]: value });
    }
    setFilters({ ...filters, [name]: value });
  };

  // Apply filters on invoices data
  useEffect(() => {
    if (invoicesData.data) {
      // Check if invoicesData.data exists
      const filtered = invoicesData.data.filter((invoice) => {
        return (
          (filters.code === "" ||
            invoice.invoiceCode.toString().includes(filters.code)) &&
          (filters.date === "" || invoice.invoiceDate.includes(filters.date)) &&
          (filters.client === "" ||
            invoice.customer.includes(filters.client)) &&
          (filters.phone === "" || invoice.phone.includes(filters.phone)) &&
          (filters.address === "" ||
            invoice.address.includes(filters.address)) &&
          // (filters.representative === '' || invoice.representative.fullName.includes(filters.representative)) &&
          (filters.status === "" || invoice.status.includes(filters.status)) &&
          (filters.governorate === "" ||
            invoice.governorate.name.includes(filters.governorate))
        );
      });
      setFilteredInvoices(filtered);
    }
  }, [filters, invoicesData]);
  const handleClearSearch = () => {
    setFilters({
      code: "",
      date: "",
      client: "",
      phone: "",
      address: "",
      user: "",
      representative: "",
      status: "",
      quantity: "",
      governorate: "",
    });
  };
  const indexOfLastInvoice = currentPage * itemsPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - itemsPerPage;
  const currentInvoices = filteredInvoices.slice(
    indexOfFirstInvoice,
    indexOfLastInvoice
  );
  const totalPages = Math.ceil(filteredInvoices.length / itemsPerPage);

  return (
    <div className="invoices-container">
      <h2>فواتير</h2>
      <hr />
      <div className="containerInput">
        <div className="w-[100%]  flex items-center justify-center">
          <button
            className="px-4 my-3 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            onClick={handleClearSearch} // Clear inputs on button click
          >
            مسح
          </button>
        </div>
        <div className="input-group">
          <label>
            الكود
            <input
              type="text"
              name="code"
              value={filters.code}
              onChange={handleFilterChange}
              placeholder="الكود"
            />
          </label>
          <label>
            التاريخ
            <input
              type="date"
              name="date"
              value={filters.date}
              onChange={handleFilterChange}
            />
          </label>
          <label>
            العميل
            <input
              type="text"
              name="client"
              value={filters.client}
              onChange={handleFilterChange}
              placeholder="العميل"
            />
          </label>
          <label>
            التليفون
            <input
              type="text"
              name="phone"
              value={filters.phone}
              onChange={handleFilterChange}
              placeholder="التليفون"
            />
          </label>
          <label>
            العنوان
            <input
              type="text"
              name="address"
              value={filters.address}
              onChange={handleFilterChange}
              placeholder="العنوان"
            />
          </label>
        </div>
        <div className="input-group">
          <label>
            المستخدم
            <input
              type="text"
              name="user"
              value={filters.user}
              onChange={handleFilterChange}
              placeholder="المستخدم"
            />
          </label>
          <label>
            المندوب
            <input
              type="text"
              name="representative"
              value={filters.representative}
              onChange={handleFilterChange}
              placeholder="المندوب"
            />
          </label>
          <label>
            الحالة
            <input
              type="text"
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              placeholder="الحالة"
            />
          </label>
          <label>
            الكمية
            <input
              type="number"
              name="quantity"
              value={filters.quantity}
              onChange={handleFilterChange}
              placeholder="الكميه"
            />
          </label>
          <label>
            المحافظه
            <input
              type="text"
              name="governorate"
              value={filters.governorate}
              onChange={handleFilterChange}
              placeholder="الكميه"
            />
          </label>
        </div>
      </div>
      <div className="table-container">
        <div className="scrollable-table">
          <table className="invoices-table">
            <thead>
              <tr>
                <th>الكود</th>
                <th>التاريخ</th>
                <th>العميل</th>
                <th>التليفون</th>
                <th>العنوان</th>
                <th>المحافظة</th>
                <th>السكرتيرة</th>
                <th>عدد الأصناف</th>
                <th>تاريخ اليومية</th>
                <th>المندوب</th>
                <th>الحالة</th>
                <th>تاريخ التعديل</th>
                <th>الفاعل</th>
                <th>الطباعه</th>
                <th>التعديل</th>
                <th>الحذف</th>
                <th>عرض</th>
              </tr>
            </thead>
            <tbody>
              {currentInvoices.map((invoice) => (
                <tr
                  className="cursor-pointer hover:bg-gray-50 transition duration-200"
                  key={invoice.id}
                >
                  <td className="px-4 py-2 text-gray-800">
                    {invoice.invoiceCode}
                  </td>
                  <td className="px-4 py-2 text-gray-800">
                    {invoice.invoiceDate.split("T")[0]}
                  </td>
                  <td className="px-4 py-2 text-gray-800">
                    {invoice.customer}
                  </td>
                  <td className="px-4 py-2 text-gray-800">{invoice.phone}</td>
                  <td className="px-4 py-2 text-gray-800 truncate">
                    {invoice.address}
                  </td>
                  <td className="px-4 py-2 text-gray-800">
                    {invoice.governorate.name}
                  </td>
                  <td className="px-4 py-2 text-gray-800">
                    {invoice.createdBy  || ''}
                  </td>
                  <td className="px-4 py-2 text-center text-gray-800">
                    {invoice.invoiceCategories.length}
                  </td>
                  <td className="px-4 py-2 text-gray-800">
                    {invoice.dateCreated.split("T")[0]}
                  </td>
                  <td className="px-4 py-2 text-gray-800">
                    {invoice.representative?.fullName || "_"}
                  </td>
                  <td
                    className={`px-4 py-2 font-semibold ${
                      invoice.status === "Paid"
                        ? "text-green-600"
                        : invoice.status === "Pending"
                        ? "text-orange-600"
                        : "text-red-600"
                    }`}
                  >
                    {invoice.status}
                  </td>
                  <td className="px-4 py-2 text-gray-800">
                    {invoice.dateCreated.split("T")[0]}
                  </td>
                  <td className="px-4 py-2 text-gray-800">
                    {invoice.userName}
                  </td>
                  <td className="px-4 py-2 text-center">
                    <button
                      className="p-2 rounded-full hover:bg-teal-100"
                      onClick={(event) => {
                        handlePrintInvoice(invoice);
                      }}
                      title="Print Invoice"
                    >
                      <FaPrint className="text-teal-600 text-lg" />
                    </button>
                  </td>
                  <td className="px-4 py-2 text-center">
                    <button
                      className="p-2 rounded-full hover:bg-purple-100"
                      onClick={(event) => {
                        handleOpenDialog(invoice);
                      }}
                      title="Edit Invoice"
                    >
                      <BsPencilSquare className="text-purple-600 text-lg" />
                    </button>
                  </td>
                  <td className="px-4 py-2 text-center">
                    <button
                      className="p-2 rounded-full hover:bg-red-100"
                      onClick={() => handleDelete(invoice)}
                      title="Delete Invoice"
                    >
                      <FaTrash className="text-red-600 text-lg" />
                    </button>
                  </td>
                  <td className="px-4 py-2 text-center">
                    <button
                      className="p-2 rounded-full hover:bg-blue-100"
                      onClick={() => showInvoice(invoice)}
                      title="View Invoice"
                    >
                      <FaEye className="text-blue-600 text-lg" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {openDialog && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div
              className="fixed inset-0 bg-black bg-opacity-30 z-40"
              onClick={handleCloseDialog} // Close on backdrop click
            ></div>

            <div className="relative z-50 bg-white rounded-lg shadow-lg p-6 max-w-4xl w-full">
              <div className="rtl">
                {/* Title */}
                <h2 className="text-2xl font-semibold mb-4 text-center text-gray-700">
                  تعديل الفاتورة
                </h2>

                {/* Customer Input */}
                <div className="mb-6">
                  <label className="block text-sm font-medium mb-2">
                    العميل
                  </label>
                  <input
                    type="text"
                    name="customer"
                    value={selectedInvoice.customer}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Row for Phone and Address */}
                <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      الهاتف
                    </label>
                    <input
                      type="text"
                      name="phone"
                      value={selectedInvoice.phone}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      العنوان
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={selectedInvoice.address}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                {/* Governorate and Representative */}
                <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      المحافظه
                    </label>
                    <select
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      value={selectedInvoice.governorateId}
                      onChange={(event) => {
                        setSelectedInvoice((prevInvoice) => ({
                          ...prevInvoice,
                          governorateId: event.target.value,
                        }));
                      }}
                      name="governorateId"
                    >
                      <option value="">اختر المحافظة</option>
                      {governorates.map((gov) => (
                        <option key={gov.id} value={gov.id}>
                          {gov.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      المندوب
                    </label>
                    <select
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      value={selectedInvoice.representativeId}
                      onChange={(event) => {
                        setSelectedInvoice((prevInvoice) => ({
                          ...prevInvoice,
                          representativeId: event.target.value,
                        }));
                      }}
                      name="representativeId"
                    >
                      <option value="" disabled>
                        اختر المندوب
                      </option>
                      {representatives?.data.map((rep) => (
                        <option key={rep.id} value={rep.id}>
                          {rep.fullName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Table Section */}
                <div className="w-full border border-gray-200 rounded-lg p-4 max-h-52 overflow-auto shadow-sm mb-6">
                  <div
                    onClick={() => setIsModalOpen(true)}
                    className="flex cursor-pointer w-[50px] mx-auto my-3 p-2 rounded-full bg-green-600 text-white items-center justify-center"
                  >
                    <FaPlus />
                  </div>
                  {selectedInvoiceItems.length ? (
                    <div className="overflow-x-auto bg-white rounded-lg shadow-md">
                      <table className="table-auto w-full">
                        <thead className="bg-gradient-to-r from-blue-500 to-blue-700 text-white">
                          <tr>
                            <th className="py-3 px-4 text-sm font-semibold text-gray-900">
                              Category
                            </th>
                            <th className="py-3 px-4 text-sm font-semibold text-gray-900">
                              Quantity
                            </th>
                            <th className="py-3 px-4 text-sm font-semibold text-gray-900">
                              Price
                            </th>
                            <th className="py-3 px-4 text-sm font-semibold text-gray-900">
                              Total
                            </th>
                            <th className="py-3 px-4 text-sm font-semibold text-gray-900">
                              Total with Shipping
                            </th>
                            <th className="py-3 px-4 text-sm font-semibold text-center text">
                              Delete
                            </th>
                            <th className="py-3 px-4 text-sm font-semibold text-center text">
                              Edit
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {selectedInvoiceItems.map((item, idx) => (
                            <tr key={idx} className="hover:bg-gray-50">
                              <td className="py-3 px-4 text-gray-800">
                                {item.category?.name}
                              </td>
                              <td className="py-3 px-4 text-gray-800 text-center">
                                {item.quantity}
                              </td>
                              <td className="py-3 px-4 text-gray-800 text-center">
                                {item.price}
                              </td>
                              <td className="py-3 px-4 text-gray-800 text-center">
                                {item.total}
                              </td>
                              <td className="py-3 px-4 text-gray-800 text-center">
                                {item.totalWithShipping}
                              </td>
                              <td className="py-3 px-4 text-center">
                                <button
                                  className="text-red-500 hover:text-red-700 transition duration-200"
                                  onClick={() => handleDeleteItem(item)}
                                  title="Delete Item"
                                >
                                  <i className="fas fa-trash"></i>
                                </button>
                              </td>
                              <td className="py-3 px-4 text-center">
                                <button
                                  className="text-green-500 hover:text-green-700 transition duration-200"
                                  onClick={() => handleEditItem(item)}
                                  title="Edit Item"
                                >
                                  <i className="fas fa-edit"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="text-center py-6 text-gray-500">
                      <p className="text-lg font-medium">No items found.</p>
                    </div>
                  )}
                </div>

                {/* Footer Actions */}
                <div className="flex justify-end gap-4">
                  <button
                    className="bg-red-400 text-white py-2 px-4 rounded-md hover:bg-red-600 transition duration-200"
                    onClick={handleCloseDialog}
                  >
                    الغاء
                  </button>
                  <button
                    className="bg-green-700 text-white py-2 px-8 rounded-md hover:bg-green-600 transition duration-200"
                    onClick={handleSaveChanges}
                  >
                    حفظ
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6">
              <div className="modal-header flex justify-between items-center">
                <h3 className="text-lg font-semibold">
                  {editMode ? "تعديل الصنف" : "إضافة صنف جديد"}
                </h3>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-xl text-gray-500 hover:text-gray-700 cursor-pointer"
                  onClick={handleCloseModal}
                />
              </div>
              <hr className="my-4" />
              <div className="modal-body">
                <div className="form-row grid grid-cols-1 gap-4">
                  <div className="form-group">
                    <label className="block text-sm font-medium text-gray-700">
                      الصنف
                    </label>
                    <select
                      value={newItem.categoryId}
                      onChange={handleCategoryChange}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">اختر الصنف</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="block text-sm font-medium text-gray-700">
                      الكمية
                    </label>
                    <input
                      type="number"
                      value={newItem.quantity}
                      onChange={(e) =>
                        setNewItem({
                          ...newItem,
                          quantity: parseInt(e.target.value),
                        })
                      }
                      placeholder="الكمية"
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>
                <div className="form-row grid grid-cols-1 gap-4 mt-4">
                  <div className="form-group">
                    <label className="block text-sm font-medium text-gray-700">
                      السعر
                    </label>
                    <input
                      type="number"
                      value={newItem.price}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (value > 0 || e.target.value === "") {
                          setNewItem({
                            ...newItem,
                            price: value,
                          });
                        }
                      }}
                      placeholder="السعر"
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  <div className="form-group">
                    <label className="block text-sm font-medium text-gray-700">
                      الخصم
                    </label>
                    <input
                      type="number"
                      value={newItem.discount}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (value > 0 || e.target.value === "") {
                          setNewItem({
                            ...newItem,
                            discount: value,
                          });
                        }
                      }}
                      placeholder="الخصم"
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer mt-4">
                <button
                  className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onClick={editMode ? handleEditItemRequest : handleSaveItem}
                >
                  <FontAwesomeIcon icon={faSave} className="mr-2" />
                  {editMode ? "حفظ التعديلات" : "إضافة الصنف"}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="pagination">
          <span>
            صفحة {currentPage} من {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
          >
            السابق
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() =>
              setCurrentPage(Math.min(totalPages, currentPage + 1))
            }
            disabled={currentPage === totalPages}
          >
            التالي
          </button>
        </div>
      </div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>
          {dialogType === "success" ? "Success" : "Error"}
        </DialogTitle>
        <DialogContent>
          <h3 className="text-center p-2 ">{dialogMessage}</h3>
        </DialogContent>
        <DialogActions>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-md"
            onClick={handleClose}
            color="primary"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Invoices;
