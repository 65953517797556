import React, { useEffect, useRef, useState } from "react";
import "./ChatApp.css";
import avatar from "../../Component/img/circle.png";
import { useSignalR } from "../../context/SingleRProvider";
import Loader from "../../Component/loader/Loader";
import { Domin } from "../../Component/function/FunctionApi";

const ChatWindow = ({ selectedFriend, conversationId }) => {
  const [newMessage, setNewMessage] = useState("");
  const { messages, sendMessage, fetchMessages, loading, setMessages } =
    useSignalR();
  const chatBodyRef = useRef(null);

  useEffect(() => {
    if (conversationId && !messages[conversationId]) {
      fetchMessages(conversationId);
    }
  }, [conversationId]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const messageData = {
        conversationId: conversationId,
        senderId: localStorage.getItem("userId_account"),
        receiverId: selectedFriend.id,
        content: newMessage,
      };
      console.log(messageData);
      sendMessage(messageData);
      fetchMessages(conversationId);
      setNewMessage("");
    }
  };

  if (!selectedFriend || !conversationId) {
    return (
      <div className="w-full">
        <div className="flex gap-10 justify-center items-center flex-col h-full">
          <h3 className="text-xl md:text-2xl">اختر محادثه</h3>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className=" flex h-[80vh]  w-full lg:w-[80%] flex-col  bg-[#f0f0f0]">
      <div className="chat-header">
        <img
          src={
            selectedFriend.userImage
              ? `${Domin}/${selectedFriend.userImage}`
              : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?semt=ais_hybrid"
          }
          alt={`${selectedFriend.displayName}'s avatar`}
          className="friend-avatar"
        />
        <h4>{selectedFriend.displayName}</h4>
      </div>
      <div className="chat-body" ref={chatBodyRef}>
        {loading ? (
          <Loader />
        ) : Array.isArray(messages) && messages.length > 0 ? (
          messages.map((message, index) => (
            <div
              key={index}
              className={`message ${
                message.senderId !== localStorage.getItem("userId_account")
                  ? "from-me"
                  : "from-them"
              }`}
            >
              <img
                src={
                  message.senderId !== localStorage.getItem("userId_account")
                    ? `${Domin}/${selectedFriend.userImage}`
                    : `${Domin}/${localStorage.getItem("userImage")}` ||
                      "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?semt=ais_hybrid"
                }
                alt=""
              />
              <div className="text break-words">
                <p>{message.content}</p>
                <div className="timestamp">{message.timestamp}</div>
              </div>
            </div>
          ))
        ) : null}
      </div>
      <div className=" flex justify-between items-center">
        <input
          type="text "
          placeholder="Type a message"
          value={newMessage}
          className="w-full rounded-sm p-1 lg:p-2 lg:text-md  border-none outline-none bg-gray-50 text-[12px]  "
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <button
          onClick={handleSendMessage}
          className="send-button  p-1! text-[12px]"
        >
          ارسال
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
