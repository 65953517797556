import { useCallback } from "react";

const usePrintInvoice = (logo) => {
  const handlePrintInvoice = useCallback((invoice) => {
    const printWindow = window.open("", "_blank");

    const invoiceCategories = invoice.invoiceCategories || [];
    const categoryRows = invoiceCategories.length
      ? invoiceCategories
          .map(
            (category) => `
              <tr>
                <td>${category.category?.name || "لا يوجد"}</td>
                <td>${category?.quantity || 0}</td>
                <td>${category?.price || 0}</td>
                <td>${category?.total || 0}</td>
              </tr>
            `
          )
          .join("")
      : `<tr><td colspan="4" style="text-align: center;">لا توجد فئات</td></tr>`;

    const formattedDate =
      new Date(invoice.invoiceDate).toLocaleDateString("ar-EG") || "N/A";

    printWindow.document.write(`
      <html>
        <head>
          <title>فواتير</title>
          <style>
            body {
              font-family: "Arial", sans-serif;
              margin: 0 auto;
              padding: 20px;
              direction: rtl;
              color: #333;
              background-color: #f9f9f9;
            }
            .page-break {
              page-break-after: always;
              margin-top: 20px;
              margin-bottom: 20px;
              padding: 20px;
              width: 80%;
              border: 2px solid #000;
              background-color: #fff;
              border-radius: 8px;
            }
            .invoice-header h2 {
              margin: 0;
              text-align: center;
              color: #2c3e50;
              font-size: 24px;
              font-weight: bold;
            }
            .row {
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
            }
            .client-info {
              width: 48%;
              padding: 15px;
              background-color: #f2f2f2;
              border-radius: 4px;
              font-size: 16px;
            }
            .client-info p {
              margin: 5px 0;
              color: #333;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin: 20px 0;
              font-size: 14px;
              background: #fff;
            }
            th, td {
              padding: 8px;
              border: 1px solid #ddd;
              text-align: center;
              font-size: 16px;
            }
            th {
              background-color: #f2f2f2;
            }
            tr:nth-child(even) {
              background-color: #f9f9f9;
            }
            .summary-table {
              width: 40%;
              margin: 0 auto;
              border-collapse: collapse;
              text-align: right;
              background-color: #f9f9f9;
            }
            .summary-table td {
              padding: 10px;
              font-size: 16px;
              color: #333;
            }
            .summary-table tr:nth-child(even) {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <div class="page-break">
            <div style="display: flex; justify-content: flex-end;">
              <img src='${logo || ""}' alt="Invoice Logo" style="width: 100px; height: auto;">
            </div>
            <h2>فاتورة رقم: ${invoice.invoiceCode || "N/A"}</h2>
            
            <div class="row">
              <div class="client-info">
                <p><strong>اسم العميل:</strong> ${invoice.customer || "N/A"}</p>
                <p><strong>العنوان:</strong> ${invoice.address || "N/A"}</p>
                <p><strong>الهاتف:</strong> ${invoice.phone || "N/A"}</p>
                <p><strong>المحافظة:</strong> ${
                  invoice.representative?.governorateName || "N/A"
                }</p>
              </div>
              <div class="client-info">
                <p><strong>تاريخ الفاتورة:</strong> ${formattedDate}</p>
                <p><strong>السكرتير:</strong> ${invoice.userName || "N/A"}</p>
                <p><strong>الهاتف:</strong> ${invoice.phone || "N/A"}</p>
                <p><strong>المندوب:</strong> ${
                  invoice.representative?.fullName || "N/A"
                }</p>
              </div>
            </div>
  
            <table>
              <thead>
                <tr>
                  <th>الصنف</th>
                  <th>الكمية</th>
                  <th>السعر</th>
                  <th>الإجمالي</th>
                </tr>
              </thead>
              <tbody>
                ${categoryRows}
              </tbody>
            </table>
  
            <table class="summary-table">
              <tbody>
                <tr>
                  <td><strong>الاجمالي</strong></td>
                  <td>${invoice.totalPrice || 0}</td>
                </tr>
                <tr>
                  <td><strong>الشحن</strong></td>
                  <td>${invoice.shipping || 0}</td>
                </tr>
                <tr>
                  <td><strong>الاجمالي مع الشحن</strong></td>
                  <td>${
                    (invoice.totalPrice || 0) + (invoice.shipping || 0)
                  }</td>
                </tr>
              </tbody>
            </table>
          </div>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
  }, [logo]);

  return { handlePrintInvoice };
};

export default usePrintInvoice;
