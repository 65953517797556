import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const useShowInvoice = () => {
  const MySwal = withReactContent(Swal);

  const showInvoice = (invoice) => {
    const productDetails = invoice.invoiceCategories
      ?.map(
        (item, index) => `
          <tr>
            <td class="border px-4 py-2">${index + 1}</td>
            <td class="border px-4 py-2">${item.category?.name || "N/A"}</td>
            <td class="border px-4 py-2">${item.quantity || "0"}</td>
            <td class="border px-4 py-2">${item.price || "0"} جنيه</td>
            <td class="border px-4 py-2">${item.discount || "0"}%</td>
            <td class="border px-4 py-2">${item.total || "0"} جنيه</td>
            <td class="border px-4 py-2">${
              item.totalWithShipping || "0"
            } جنيه</td>
            
          </tr>
        `
      )
      .join("");

    MySwal.fire({
      title: `<h2 class="text-2xl font-bold text-gray-800">بيانات الفاتورة</h2>`,
      html: `
        <div class="text-right text-lg">
          <p><strong>الكود:</strong> ${invoice.invoiceCode || "N/A"}</p>
          <p><strong>التاريخ:</strong> ${
            invoice.invoiceDate?.split("T")[0] || "N/A"
          }</p>
          <p><strong>العميل:</strong> ${invoice.customer || "N/A"}</p>
          <p><strong>التليفون:</strong> ${invoice.phone || "N/A"}</p>
          <p><strong>العنوان:</strong> ${invoice.address || "N/A"}</p>
          <p><strong>المحافظة:</strong> ${
            invoice.governorate?.name || "N/A"
          }</p>
          <p><strong>عدد الأصناف:</strong> ${
            invoice.invoiceCategories?.length || "0"
          }</p>
          <p><strong>إجمالي السعر:</strong> ${
            invoice.totalPrice - invoice.shipping || "N/A"
          } جنيه</p>
          <p><strong>الشحن:</strong> ${invoice.shipping || "N/A"} جنيه</p>
          <p><strong>الإجمالي مع الشحن:</strong> ${
            invoice.totalPrice || "N/A"
          } جنيه</p>
          <p><strong>حالة الفاتورة:</strong> ${invoice.status || "N/A"}</p>
          <p><strong>اسم المستخدم:</strong> ${invoice.userName || "N/A"}</p>
          <p><strong>تاريخ اليومية:</strong> ${
            invoice.dailyDate?.split("T")[0] || "N/A"
          }</p>
          <p><strong>ملاحظات:</strong> ${invoice.note || "N/A"}</p>
        </div>
        <div class="mt-4">
          <h3 class="text-xl font-bold text-gray-800 mb-2">تفاصيل الأصناف</h3>
          <table class=" w-full text-right h-[100px] overflow-x-auto border-collapse border border-gray-300">
            <thead>
              <tr>
                <th class="border px-4 py-2">#</th>
                <th class="border px-1 py-2">اسم المنتج</th>
                <th class="border px-1 py-2">الكمية</th>
                <th class="border px-1 py-2">السعر</th>
                <th class="border px-1 py-2">الخصم</th>
                <th class="border px-1 py-2">الإجمالي</th>
                <th class="border px-1 py-2">الاجمالي مع الشحن</th>
              </tr>
            </thead>
            <tbody>
              ${
                productDetails ||
                `<tr><td colspan="6" class="border px-4 py-2">لا توجد بيانات</td></tr>`
              }
            </tbody>
          </table>
        </div>
      `,
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText: "إغلاق",
      confirmButtonClass:
        "bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-lg",
      customClass: {
        popup: "shadow-lg border-2 border-purple-600 rounded-lg",
      },
    });
  };

  return { showInvoice };
};

export default useShowInvoice;
